/*
 * action types
 */

export const STEP_FORWARD = 'STEP_FORWARD';
export const STEP_BACK = 'STEP_BACK';
export const TO_STEP = 'TO_STEP';

/*
 * action creators
 */

export const stepForward = () => {
  return { type: STEP_FORWARD }
}

export const stepBack = () => {
  return { type: STEP_BACK }
}

export const toStep = (index) => {
  return { type: TO_STEP, index }
}

/**
 * BOUNDED ACTIONS
 */
