import React from 'react';
import cn from 'classnames';

export const ForwardButton = ({ isHidden, caption, isActive, clickHandle }) => {
    if (isHidden) {
        return (
            <></>
        );
    }

    const btnClass = cn({
        'nav-arrow': true,
        active: isActive
    });

    return (
     <span className={btnClass} onClick={clickHandle}>{caption} &rarr;</span>
    );
}