/*
 * action types
 */

export const SET_USERNAME = 'SET_USERNAME';
export const SET_USERPHONE = 'SET_USERPHONE';
export const SET_USEREMAIL = 'SET_USEREMAIL';
export const SET_STATUS = 'SET_STATUS';


/*
 * action creators
 */

export const setUsername = (event) => {
  return {
    type: SET_USERNAME,
    payload: event.target.value
  }
}

export const setPhone = (event) => {
  return {
    type: SET_USERPHONE,
    payload: event.target.value
  }
}

export const setEmail = (event) => {
  return {
    type: SET_USEREMAIL,
    payload: event.target.value
  }
}

export const setStatus = (status) => {
  return {
    type: SET_STATUS,
    payload: status
  }
}


/**
 * BOUNDED ACTIONS
 */
