import { connect } from 'react-redux'
import { setAnswer } from '../actions/answers';
import { Step_1 } from '../steps/Step_1';
import { quiz } from '../quiz';
import _ from 'lodash';

const mapStateToProps = state => {
  const question = quiz[state.navigation.currentStep];
  const choosenAnswer = _.find(state.overall.passed, { qid: question.id });
  
  return {
    question: question,
    choosenAnswer: choosenAnswer ? choosenAnswer : null
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickAnswer: (qid, aid) => () => {dispatch(setAnswer(qid, aid))},
  }
}

export const StepType1 = connect(mapStateToProps, mapDispatchToProps)(Step_1);
