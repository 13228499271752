import { quiz } from '../quiz';

export const initialState = {
  navigation: {
    currentStep: -1,
    total: quiz.length
  },

  userdata: {
    name: '',
    phone: '+7',
    email: '',
    status: 'pending',
    token: ''
  },

  overall: {
    passed: [
    ],
    totalAmount: 0
  }
};