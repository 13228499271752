import React from 'react';

export const Progress = ({ count, total }) => {
    const percent = Math.ceil((count * 100) / total);
    return (
        <div className="w-100 pb-4">
            <div className="my-2" style={{ color: 'var(--calc-main-color)'}}>
                {`${percent} %`}
            </div>
            <div className="progress" style={{ height: '3px' }}>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${percent}%`, background: 'var(--calc-main-color)' }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
                <div className="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" style={{ width: `${100 - percent}%`, background: 'var(--calc-text-color-dark)' }} aria-valuenow={percent} aria-valuemin="0" aria-valuemax="100"></div>
            </div>
        </div>
    );
}