import { connect } from 'react-redux';
import { Progress } from '../presentational/Progress';

const mapStateToProps = (state) => {
    return {
        count: state.overall.passed.length,
        total: state.navigation.total
    };
}

export const ProgressContainer = connect(mapStateToProps)(Progress);