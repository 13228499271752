import { connect } from 'react-redux';
import { stepBack } from '../actions/steps';
import { BackButton } from '../presentational/BackButton';

const mapStateToProps = (state) => {
    return {
        isHidden: state.navigation.currentStep === -1 || state.userdata.status !== 'pending',
        caption: state.navigation.currentStep === 0 ? 'В начало' : 'Назад'
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clickHandle: () => dispatch(stepBack())
    };
}

export const BackButtonContainer = connect(mapStateToProps, mapDispatchToProps)(BackButton);