import { combineReducers } from 'redux';
import { initialState } from '../state/init';
import { STEP_FORWARD, STEP_BACK, TO_STEP } from '../actions/steps';
import { SET_ANSWER, SET_VALUE, SET_DUMMY_ANSWER } from '../actions/answers';
import { SET_STATUS, SET_USEREMAIL, SET_USERNAME, SET_USERPHONE } from '../actions/userdata';
import { quiz } from '../quiz';
import { getTotalAmount } from '../quiz';
import _ from 'lodash';
import update from 'immutability-helper';

export const navigation = (nav = initialState.navigation, action) => {
  switch (action.type) {
    case STEP_FORWARD:
      return { ...nav, currentStep: nav.currentStep !== nav.total - 1 ? nav.currentStep + 1 : nav.currentStep};
    case STEP_BACK:
      return { ...nav, currentStep: nav.currentStep !== -1 ? nav.currentStep - 1 : -1};
    case TO_STEP:
      return { ...nav, currentStep: action.index};
    default:
      return nav;
  };
};

const filterPhone = string => {
  const filtered = string.split('').filter(char => {
      return (Number(char) && char !== ' ') || char === '+' || char === '0';
  });

  return filtered.slice(0, 12).join('');
}

const userdata = (userData = initialState.userdata, action) => {
  switch (action.type) {
      case SET_USERNAME:
          return { ...userData, name: action.payload.toUpperCase().slice(0, 19) };
      case SET_USERPHONE:
          return { ...userData, phone: filterPhone(action.payload) };
      case SET_USEREMAIL:
          return { ...userData, email: action.payload.slice(0, 80) };
      case SET_STATUS:
          return { ...userData, status: action.payload };
      default:
          return userData;
  };
}


export const overall = (state = initialState.overall, action) => {
  if (action.type === SET_DUMMY_ANSWER) {
    return {
      ...state,
      passed: [...state.passed, {}]
    };
  }

  if (action.type === SET_VALUE) {
    const answerExists = _.find(state.passed, { qid: action.qid });
    const existingAnswerQuestionIndex = _.findIndex(state.passed, { qid: action.qid }); 
    const newAnswerToState = { qid: action.qid, value: action.value };

    if (answerExists) {
      const updatedPassed = update(state.passed, { [existingAnswerQuestionIndex]: {$set: newAnswerToState} });
      return {
            ...state,
            passed: updatedPassed,
            totalAmount: getTotalAmount([...updatedPassed])
          }; 
    };
  
    return {
      passed: [
        ...state.passed, newAnswerToState
      ],
      totalAmount: getTotalAmount([...state.passed, newAnswerToState])
    };
  }

  if (action.type !== SET_ANSWER) {
    return state;
  }
  
  const answersForQuestionId = _.find(quiz, { id: action.qid }).answers;
  const choosenAnswer = _.find(answersForQuestionId, { id: action.aid });
  const answerExists = _.find(state.passed, { qid: action.qid});
  const existingAnswerQuestionIndex = _.findIndex(state.passed, { qid: action.qid});  
  const newAnswerToState = {qid: action.qid, ...choosenAnswer};

  if (answerExists) {
    const updatedPassed = update(state.passed, { [existingAnswerQuestionIndex]: {$set: newAnswerToState} });
    return {
          ...state,
          passed: updatedPassed,
          totalAmount: getTotalAmount([...updatedPassed])
        }; 
  };

  return {
    passed: [
      ...state.passed, newAnswerToState
    ],
    totalAmount: getTotalAmount([...state.passed, newAnswerToState])
  };
};

export const combinedReducer = combineReducers({
  navigation,
  overall,
  userdata
});
