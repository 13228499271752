import React from 'react';

// Active ontainers
import { StepDisplay } from './containers/StepDisplay';

export const App = () => {
  return (
    <StepDisplay />
  );
}



