import { connect } from 'react-redux'
import { setValue } from '../actions/answers';
import { DiscreteSlider } from '../steps/Slider';
import _ from 'lodash';

const mapStateToProps = state => {
  return {
    value: _.find(state.overall.passed, { qid: 'q4' }).value
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onChange: (qid, value) => dispatch(setValue('q4', value))
  }
}

export const Slidercontainer = connect(mapStateToProps, mapDispatchToProps)(DiscreteSlider);