import React from 'react';

export const BackButton = ({ isHidden, caption, clickHandle }) => {
    if (isHidden) {
        return (
            <></>
        );
    }

    return (
     <span className='nav-arrow' onClick={clickHandle}>&larr; {caption}</span>
    );
}