import { connect } from 'react-redux';
import { stepForward } from '../actions/steps';
import { ForwardButton } from '../presentational/ForwardButton';
import _ from 'lodash';
import { quiz } from '../quiz';

const answerExsistsInCurrent = (state) => {
    if (state.navigation.currentStep === -1) {
        return false;
    }
    const questionId = quiz[state.navigation.currentStep].id;
    return _.find(state.overall.passed, { 'qid': questionId });
};

const mapStateToProps = (state) => {
    return {
        isHidden: state.navigation.currentStep === quiz.length - 1 || state.navigation.currentStep === -1,
        caption: 'Далее',
        isActive: answerExsistsInCurrent(state)
    };
}

const mapDispatchToProps = (dispatch) => {
    return {
        clickHandle: () => dispatch(stepForward())
    };
}

export const ForwardButtonContainer = connect(mapStateToProps, mapDispatchToProps)(ForwardButton);