import _ from 'lodash';

export const getTotalAmount = ([...answers]) => {
  const taxesTypes = {
    a0: 100,
    a1: 200,
    a2: 300,
    a3: 400,
    a4: 500,
  };

  const dispatch = {
    a0: { a0: 11, a1: 12, a2: 13, a3: 14, a4: 15 },
    a1: { a0: 16, a1: 17, a2: 18, a3: 19, a4: 20 },
    a2: { a0: 21, a1: 22, a2: 23, a3: 24, a4: 25 },
    a3: { a0: 26, a1: 27, a2: 28, a3: 29, a4: 30 },
    a4: { a0: 31, a1: 32, a2: 33, a3: 34, a4: 35 },
  };

  const costPerEmployee = 450;

  const employee = {
    a0: costPerEmployee * 1,
    a1: costPerEmployee * 10,
    a2: costPerEmployee * 50,
    a3: costPerEmployee * 100,
    a4: costPerEmployee * 300,
  };

  return answers.reduce((acc, current) => {
    switch (current.qid) {
      case 'q1':
        return acc += taxesTypes[current.id];
      case 'q2':
        {
          const firstQuestionAnswer = _.find(answers, { qid: 'q1' }).id;
          return acc += dispatch[firstQuestionAnswer][current.id];
        }
      case 'q3':
        return acc += employee[current.id];
      case 'q4':
        return acc += costPerEmployee * current.value;
      default:
        return acc;
    }
  }, 0);
};

export const texts = window.texts;


export const quiz = window.quiz;