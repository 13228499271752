import React from 'react';
import cn from 'classnames';
import { ProgressContainer } from '../containers/ProgressContainer';

export const Step_1 = ({ question, onClickAnswer, choosenAnswer }) => {

  const answers = question.answers.map(answer => {
    const currentClass = choosenAnswer ? cn({
      'answer-btn': true,
      'active': choosenAnswer.id === answer.id
    }) : 'answer-btn';

    return (
      <p className={currentClass} key={answer.id} onClick={onClickAnswer(question.id, answer.id)}>
        {answer.text}
      </p>
    );
  });

  return (
    <div className='question px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-sm-5 px-3 py-3'>
      <ProgressContainer />
      <span className="h2 question-headline">{question.name}</span>
      <p className="calc-desc">{question.description}</p>
      <div className={'answers-wrapper'}>
        {[...answers]}
      </div>
    </div>
  );
};