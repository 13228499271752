export const SET_ANSWER = 'SET_ANSWER';
export const SET_VALUE = 'SET_VALUE';
export const SET_DUMMY_ANSWER = 'SET_DUMMY_ANSWER';

export const setAnswer = (qid, aid) => {
  return {
    type: SET_ANSWER,
    qid,
    aid
  }
}

export const setValue = (qid, value) => {
  return {
    type: SET_VALUE,
    qid,
    value
  }
}

export const setDummyAnswer = () => {
  return {
    type: SET_DUMMY_ANSWER
  }
}