import { connect } from 'react-redux';
import { Form } from '../steps/Form';
import { setUsername, setPhone, setEmail, setStatus } from '../actions/userdata';
import { setDummyAnswer } from '../actions/answers';
import { quiz } from '../quiz';

const mapStateToProps = state => {
  return {
    username: state.userdata.name,
    userphone: state.userdata.phone,
    useremail: state.userdata.email,
    overall: state.overall.passed,
    status: state.userdata.status,
    answers: state.overall.passed,
    question: quiz[state.navigation.currentStep]
  }
}

const mapDispatchToProps = dispatch => {
  return {
    usernameChange: (username, userphone) => (e) => {
      if (username.length > 2 && userphone.length === 11) {
        dispatch(setDummyAnswer())
      }
      dispatch(setUsername(e));
    },
    userphoneChange: (username, userphone) => (e) => {
      if (username.length > 2 && userphone.length === 11) {
        dispatch(setDummyAnswer())
      }
      dispatch(setPhone(e))
    },
    useremailChange: (e) => {
      dispatch(setEmail(e))
    },
    // onClickAnswer: (qid, aid) => () => {dispatch(setAnswer(qid, aid))},
    statusChange: status => { dispatch(setStatus(status)) }
  }
}

export const Userdata = connect(mapStateToProps, mapDispatchToProps)(Form);
