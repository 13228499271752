import React from 'react';
import './App.css';
import { StepType1 } from './containers/Step_1';
import { Slidercontainer } from './containers/Slidercontainer';
import { Userdata } from './containers/Userdata';
import { BackButtonContainer } from './containers/BackButton';
import { ForwardButtonContainer } from './containers/ForwardButton';
import { quiz } from './quiz';
import { Start } from './presentational/Start';



const getCurrentPage = (count) => {
  switch (count) {
    case -1:
      return Start;
    case count > quiz.length:
      return Start;
    default:
      const dispatchType = {
        'StepType1': StepType1,
        'Slidercontainer': Slidercontainer,
        'Form': Userdata
      };
      return dispatchType[quiz[count].type];
  }
}

export const Display = ({ activeStep, onClickForward, onClickBack }) => {
  const Current = getCurrentPage(activeStep);

  return (
    <div className="App">
      <div className={'wrapper'}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-2">
              <BackButtonContainer />
            </div>
            <div className="col-lg-8"><Current startAction={onClickForward} /></div>
            <div className="col-lg-2">
              <ForwardButtonContainer />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};