import { connect } from 'react-redux'
import { stepBack, stepForward } from '../actions/steps';

// Presentational
import { Display } from '../Display';

const mapStateToProps = state => {
  return {
    activeStep: state.navigation.currentStep
  }
}

const mapDispatchToProps = dispatch => {
  return {
    onClickForward: () => {dispatch(stepForward())},
    onClickBack: () => {dispatch(stepBack())},
  }
}

export const StepDisplay = connect(mapStateToProps, mapDispatchToProps)(Display);

