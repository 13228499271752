import React from 'react';
import { texts } from '../quiz';

export const Start = ({ startAction }) => {
  return (
    <div className='question px-xxl-5 px-xl-5 px-lg-5 px-md-5 px-sm-5 px-3 py-3'>
      <span className="h2 my-3">{texts.start.headline.firstLine}<br />{texts.start.headline.secondline}</span>
      <p className="calc-desc mt-3 mb-5">{texts.start.description.firstLine}<br />{texts.start.description.secondLine}</p>
      <span className="submit_btn active" onClick={startAction}>{texts.start.button}</span>
    </div>
  );
}